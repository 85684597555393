import React from 'react';
import { formatTime } from '../Modules/time'
import { getIncidentReportByUserID, clearIncidentsAndReports } from '../../api/api'
import styled from 'styled-components'

const NewReportButton = styled.button`
  display: inline-block;
  color: #ec2c38;
  font-size: 1em;
  border: 2px solid #ec2c38;
  border-radius: 3px;
  display: block;
  width: 100%;
`

const InputRow = styled.div`
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
`

const ReportsList = styled.ul`
  padding: 0;
  list-style-type: none;
`

const ListItem = styled.li`
`

const ReportContainer = styled.div`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
`

const Title = styled.h2`
  color: white;
`

function LogScreenView({userObj, setLogsUpdated}) {
  const [myLogs, setMyLogs] = React.useState()

  React.useEffect( ()=> {
    getIncidentReportByUserID(setMyLogs, userObj.ID)
  }, [userObj]);

  return( 
    <Container>
      <InputRow>
        <NewReportButton onClick={()=>setLogsUpdated(false)}>Submit New Report</NewReportButton>
        {
          userObj?.SP_User ? <NewReportButton onClick={() => clearIncidentsAndReports(setMyLogs)}>Clear my logs</NewReportButton> : null
        }
      </InputRow>
      <Title>Submitted Reports:</Title>
      <ReportsList>
      {
        myLogs?.map(log => {return(
          <ReportContainer key={log.ID}>
            <ListItem>Time: {formatTime(log.Created_On, 'h:m.sq')}</ListItem>
            <ListItem>Students: {log.Head_Count}</ListItem> 
            <ListItem>Staff: {log.Staff_Count}</ListItem>
            <ListItem>Notes: {log.Notes}</ListItem>
          </ReportContainer>
        )})
      }
      </ReportsList>
    </Container>
  )
}

export default LogScreenView;
import React from 'react'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'

const MenuContainer = styled.div`
    height: 100%;
    width: 40%;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #9dbce2;
    overflow-x: hidden;
    transition: 0.5s;
    border: 2px solid white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const CloseMenuButton = styled.button`
    color: black;
    font-size: 2em;
    border: 0px solid #ec2c38;
    border-radius: 0px;
    background-color: #9dbce2;
    display: flex;
    justify-content: flex-end;
`
const MenuButton = styled.button`
    color: #ec2c38;
    font-size: 1em;
    border: 1px solid #ec2c38;
    border-radius: 4px;
    background-color: white;
`

function Menu({ showMenu, setShowMenu, logsUpdated, setLogsUpdated, userObj }) {

    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    function handleViewReports() {
        setLogsUpdated(!logsUpdated)
        setShowMenu(false)
    }

    return (
        <MenuContainer open={showMenu}>
            <CloseMenuButton onClick={() => setShowMenu(false)}>X</CloseMenuButton>
            <p>{userObj.Name_First} {userObj.Name_Last}</p>
            <MenuButton onClick={handleViewReports}>{logsUpdated ? 'Submit Report' : 'View Reports'}</MenuButton>
            <MenuButton onClick={signOut}>Sign Out</MenuButton>
        </MenuContainer>
    )
}

export default Menu
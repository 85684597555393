import React from 'react';
import LogScreen from './components/LogScreen/logScreenMain'
import LogScreenView from './components/LogScreen/logScreenView'
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { getUserIDandBuildingID } from './api/api';
import Menu from './components/Menu'
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports'
import styled from 'styled-components'

Amplify.configure(awsconfig);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-around;
  min-height: 100vh;
  background-color: #9dbce2;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 10vh;
`

function App() {
  const [userIDExt, setUserIDExt] = React.useState(1);
  const [userObj, setUserObj] = React.useState();
  const [logsUpdated, setLogsUpdated] = React.useState()
  const [ showMenu, setShowMenu ] = React.useState(false)

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(myUser => setUserIDExt(myUser.attributes.sub))
  }, [userIDExt]);

  React.useEffect( ()=> {
    getUserIDandBuildingID(setUserObj, userIDExt)
  }, [userIDExt])

  function handleClick(e) {
    if(showMenu) {
      setShowMenu(false)
    }
  }

  return (
    <Container>
      <Header>
        {
          showMenu ? 
          <Menu showMenu={showMenu} setShowMenu={setShowMenu} logsUpdated={logsUpdated} setLogsUpdated={setLogsUpdated} userObj={userObj}/> 
          : <button onClick={() => setShowMenu(true)}>Menu</button>
        }
      </Header>
      {
        userObj ?
        <div onClick={handleClick}>
          {
            logsUpdated ? 
            <LogScreenView userObj={userObj} setLogsUpdated={setLogsUpdated} />
            :
            <LogScreen userObj={userObj} setLogsUpdated={setLogsUpdated}/>
          }
        </div> 
        : 
        <p>It looks like your user is not completly set up yet. Please reach out to your organization's IT representative to complete the setup.</p>
      }
    </Container>
  );
}

export default withAuthenticator(App);
